var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('canvas',{staticClass:"hidden",attrs:{"id":"imageResizer"}}),_c('div',{staticClass:"filter-panel mb-5 flex items-center"},[_c('div',{staticClass:"inline-block"},[_c('v-text-field',{staticClass:"text-[13px]",staticStyle:{"width":"260px"},attrs:{"placeholder":"Campaign or adgroup name...","outlined":"","dense":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1),_c('div',{staticClass:"inline-block ml-2"},[_c('v-select',{staticClass:"text-[13px]",attrs:{"label":"Sort","outlined":"","items":_vm.sortSelections,"item-text":"text","item-value":"value","dense":"","hide-details":""},model:{value:(_vm.selectedSortType),callback:function ($$v) {_vm.selectedSortType=$$v},expression:"selectedSortType"}})],1),_c('div',{staticClass:"inline-block w-[500px] ml-2"},[_c('v-autocomplete',{attrs:{"clearable":"","label":"Campaigns","outlined":"","auto-select-first":"","dense":"","items":Array.from(new Set(_vm.adgroupData.map((x) => x.campaign_name))),"hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',{staticClass:"text-[12px]"},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption ml-2"},[_vm._v(" (+"+_vm._s(_vm.filterData.campaigns.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.filterData.campaigns),callback:function ($$v) {_vm.$set(_vm.filterData, "campaigns", $$v)},expression:"filterData.campaigns"}})],1)]),_c('div',{staticClass:"border rounded"},_vm._l((_vm.adgroupData
        .filter((x) => _vm.filterData.campaigns.includes(x.campaign_name))
        .filter(
          (x) =>
            new RegExp(_vm.filterData.searchKey, 'i').test(x.campaign_name) ||
            new RegExp(_vm.filterData.searchKey, 'i').test(x.name)
        )),function(adgroup){return _c('div',{key:adgroup.id},[_c('div',{staticClass:"flex items-center panel-header p-4 hover:bg-gray-100 cursor-pointer",class:{
          edited: _vm.isAdgroupModified(adgroup.id),
          'bg-gray-100': _vm.selectedAdsetPanel === adgroup.id,
        },on:{"click":function($event){return _vm.handleOnPanelView(adgroup.id)}}},[_c('span',{staticClass:"font-semibold panel-campaign-name text-[11px]"},[_vm._v(" "+_vm._s(adgroup.campaign_name)+" "+_vm._s(adgroup.id)+" ")]),_c('span',{staticClass:"mx-2 font-semibold text-[10px]"},[_vm._v(" > ")]),_c('span',{staticClass:"font-semibold panel-adgroup-name text-[11px]"},[_vm._v(" "+_vm._s(adgroup.name)+" ")])]),(_vm.selectedAdsetPanel === adgroup.id)?_c('div',[(adgroup.loading_assets)?_c('div',[_c('div',[_c('v-skeleton-loader',{attrs:{"type":"article"}})],1)]):_c('div',[(adgroup.assets && adgroup.selectedCreatives)?_c('div',[_c('div',{staticClass:"py-1 px-3"},[_vm._m(0,true),_c('div',{staticClass:"w-full my-5"},[_c('div',{staticClass:"grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3"},_vm._l((adgroup.selectedCreatives),function(ad,selectedCreativeIdx){return _c('div',{key:ad.ad_id,staticClass:"rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100",class:{
                      selected: ad.selected,
                    },on:{"click":function($event){return _vm.handleSelectCreative(adgroup.id, selectedCreativeIdx)}}},[_c('div',{staticClass:"flex items-center justify-between"},[_c('span',{staticClass:"text-[11px]",class:ad.selected
                            ? 'text-gray-700 font-semibold'
                            : 'text-gray-400'},[_vm._v(" "+_vm._s(ad.ad_name)+" ")]),(ad.improvement)?_c('span',{staticClass:"text-[10px] ml-2",class:{
                          'font-semibold': ad.selected,
                          'text-gray-400': !ad.selected,
                          'text-lime-700': ad.improvement >= 0 && ad.selected,
                          'text-rose-700': ad.improvement < 0 && ad.selected,
                        }},[_vm._v(" "+_vm._s(ad.improvement.toFixed(2))+"% ")]):_vm._e()])])}),0)]),_c('v-divider',{})],1),_c('div',{staticClass:"py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4"},[_c('h1',{staticClass:"mb-2 font-semibold text-[12px] uppercase"},[_vm._v(" 1. Descriptions ")]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},_vm._l((adgroup.assets.descriptions),function(description){return _c('div',{key:description.resource_name},[_c('v-text-field',{staticClass:"text-[13px]",attrs:{"label":"Description","outlined":"","dense":"","hide-details":"","maxlength":"90"},model:{value:(description.value),callback:function ($$v) {_vm.$set(description, "value", $$v)},expression:"description.value"}}),_c('div',{staticClass:"mt-1 text-[10px] flex items-center font-semibold"},[_c('span',{staticClass:"ml-1",class:{
                        'text-lime-700':
                          description.performance_label === 'BEST',
                        'text-red-700':
                          description.performance_label === 'LOW',
                        'text-sky-700':
                          description.performance_label === 'GOOD',
                        'text-orange-700':
                          description.performance_label === 'LEARNING',
                      }},[_vm._v(" "+_vm._s(description.performance_label)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',{class:{
                        'text-lime-700':
                          description.policy_summary.approval_status ===
                          'APPROVED',
                        'text-red-700':
                          description.policy_summary.approval_status ===
                          'DISAPPROVED',
                      }},[_vm._v(" "+_vm._s(description.policy_summary.approval_status)+" ")])]),(
                      adgroup.assets.descriptions.find(
                        (x) =>
                          x.id !== description.id &&
                          x.value === description.value
                      )
                    )?_c('span',{staticClass:"text-red-600 text-[10px] font-semibold"},[_vm._v("This description has been used already in another field.")]):_vm._e()],1)}),0)]),_c('div',{staticClass:"py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4"},[_c('h1',{staticClass:"mb-2 font-semibold text-[12px] uppercase"},[_vm._v(" 2. Headlines ")]),_c('div',{staticClass:"grid grid-cols-3 gap-4"},_vm._l((adgroup.assets.headlines),function(headline){return _c('div',{key:headline.resource_name},[_c('v-text-field',{staticClass:"text-[13px]",attrs:{"label":"Headline","outlined":"","dense":"","hide-details":"","maxlength":"30"},model:{value:(headline.value),callback:function ($$v) {_vm.$set(headline, "value", $$v)},expression:"headline.value"}}),_c('div',{staticClass:"mt-1 text-[10px] flex items-center font-semibold"},[_c('span',{staticClass:"ml-1",class:{
                        'text-lime-700':
                          headline.performance_label === 'BEST',
                        'text-red-700': headline.performance_label === 'LOW',
                        'text-sky-700': headline.performance_label === 'GOOD',
                        'text-orange-700':
                          headline.performance_label === 'LEARNING',
                      }},[_vm._v(" "+_vm._s(headline.performance_label)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',{class:{
                        'text-lime-700':
                          headline.policy_summary.approval_status ===
                          'APPROVED',
                        'text-red-700':
                          headline.policy_summary.approval_status ===
                          'DISAPPROVED',
                      }},[_vm._v(" "+_vm._s(headline.policy_summary.approval_status)+" ")])]),(
                      adgroup.assets.headlines.find(
                        (x) =>
                          x.id !== headline.id && x.value === headline.value
                      )
                    )?_c('span',{staticClass:"text-red-600 text-[10px] font-semibold"},[_vm._v("This headline has been used already in another field.")]):_vm._e()],1)}),0)]),_c('div',{staticClass:"py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4"},[_c('h1',{staticClass:"mb-2 font-semibold text-[12px] uppercase"},[_vm._v(" 3. Images ("+_vm._s(adgroup.assets.images.filter((x) => !x.selected).length)+" / 20) ")]),(adgroup.assets.images.filter((x) => x.selected).length)?_c('div',{staticClass:"rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] border border-sky-200 mb-2"},[_vm._v(" "+_vm._s(adgroup.assets.images.filter((x) => x.selected).length)+" image(s) will be deleted. ")]):_vm._e(),_c('div',{staticClass:"grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3"},_vm._l((adgroup.assets.images),function(image){return _c('div',{key:image.resource_name,staticClass:"flex flex-col items-center justify-between hover:bg-gray-200 p-3 cursor-pointer",class:{ 'bg-gray-200': image.selected },on:{"click":function($event){image.selected = !image.selected}}},[_c('div',{staticClass:"w-full text-center text-ellipsis overflow-hidden"},[_c('span',{staticClass:"text-[10px] font-semibold"},[_vm._v(" "+_vm._s(image.name)+" ")])]),_c('div',{staticClass:"adgroup_image rounded-[3px] cursor-pointer",class:{
                      'contrast-[0.1]': image.selected,
                      'hover:shadow-lg hover:contrast-75': !image.selected,
                    },style:({ backgroundImage: `url(${image.value})` })}),_c('div',{staticClass:"mt-1 text-[10px] flex items-center font-semibold"},[_c('span',{staticClass:"ml-1",class:{
                        'text-lime-700': image.performance_label === 'BEST',
                        'text-red-700': image.performance_label === 'LOW',
                        'text-sky-700': image.performance_label === 'GOOD',
                        'text-orange-700':
                          image.performance_label === 'LEARNING',
                      }},[_vm._v(" "+_vm._s(image.performance_label)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',{class:{
                        'text-lime-700':
                          image.policy_summary.approval_status === 'APPROVED',
                        'text-red-700':
                          image.policy_summary.approval_status ===
                          'DISAPPROVED',
                      }},[_vm._v(" "+_vm._s(image.policy_summary.approval_status)+" ")])])])}),0),_c('div',{staticClass:"mt-3"},[(
                    adgroup.assets.images.filter((x) => !x.selected).length +
                      adgroup.assets.request_images.length >
                    20
                  )?_c('div',{staticClass:"rounded bg-red-50 text-red-800 font-semibold p-5 text-[11px] border border-red-200 mb-2"},[_vm._v(" You need to unselect "+_vm._s(adgroup.assets.images.filter((x) => !x.selected).length + adgroup.assets.request_images.length - 20)+" images. ")]):_vm._e()]),(adgroup.assets.request_images.length)?_c('div',{staticClass:"mt-3"},[_c('v-divider',{staticClass:"mb-3"}),_vm._l((adgroup.assets.request_images),function(image,imageIdx){return _c('div',{key:imageIdx,staticClass:"inline-block p-1 bg-gray-50 mr-1.5 cursor-pointer hover:bg-gray-100",on:{"click":function($event){return _vm.removeImage(imageIdx, adgroup.id)}}},[_c('div',{staticClass:"max-w-[100px] bg-gray-50 mx-auto rounded-[3px]"},[_c('img',{staticClass:"rounded-[3px] mx-auto",staticStyle:{"max-height":"50px","max-width":"95px"},attrs:{"src":image.signed_url}})]),_c('span',{staticClass:"ml-2 text-[11px] font-semibold mx-auto"},[_vm._v(" "+_vm._s(image.filename)+" ")])])})],2):_vm._e()]),_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"border rounded bg-gray-50 hover:bg-blue-100 hover:border-blue-300 hover:text-blue-600 p-4 text-[11px] font-semibold border-dashed cursor-pointer mt-4",on:{"click":function($event){return _vm.clickImageSelectionDialog(adgroup)}}},[_vm._v(" + Choose Images ("+_vm._s(adgroup.assets.request_images.length)+" selected) ")])]),_c('div',{staticClass:"py-1 px-3 rounded-[3px] mt-4"},[_c('h1',{staticClass:"mb-2 font-semibold text-[12px] uppercase"},[_vm._v(" 4. Playable HTML Pages ("+_vm._s(adgroup.assets.media_bundles.filter((x) => !x.selected) .length)+" / 20) ")]),_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3"},_vm._l((adgroup.assets.media_bundles),function(html){return _c('div',{key:html.resource_name,staticClass:"p-4 cursor-pointer hover:bg-gray-100",class:{
                    'bg-rose-50': html.selected,
                    'bg-green-50': !html.selected,
                  },on:{"click":() => {
                      html.selected = !html.selected;
                      adgroup.assets.request_media_bundles = [];
                    }}},[_c('div',{staticClass:"w-full text-center text-ellipsis overflow-hidden"},[_c('span',{staticClass:"text-[10px] font-semibold"},[_vm._v(" "+_vm._s(html.name)+" ")])]),_c('div',{staticClass:"w-full rounded-[3px] flex items-center justify-center"},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-file-code",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36","viewBox":"0 0 24 24","stroke-width":"1","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M14 3v4a1 1 0 0 0 1 1h4"}}),_c('path',{attrs:{"d":"M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"}}),_c('path',{attrs:{"d":"M10 13l-1 2l1 2"}}),_c('path',{attrs:{"d":"M14 13l1 2l-1 2"}})])]),_c('div',{staticClass:"mt-1 text-[10px] flex items-center font-semibold justify-center"},[_c('span',{staticClass:"ml-1",class:{
                        'text-lime-700': html.performance_label === 'BEST',
                        'text-red-700': html.performance_label === 'LOW',
                        'text-sky-700': html.performance_label === 'GOOD',
                        'text-orange-700':
                          html.performance_label === 'LEARNING',
                      }},[_vm._v(" "+_vm._s(html.performance_label)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',{class:{
                        'text-lime-700':
                          html.policy_summary.approval_status === 'APPROVED',
                        'text-red-700':
                          html.policy_summary.approval_status ===
                          'DISAPPROVED',
                      }},[_vm._v(" "+_vm._s(html.policy_summary.approval_status)+" ")])])])}),0),_c('div',[_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"w-[320px]"},[_c('v-combobox',{staticClass:"text-[13px]",attrs:{"multiple":"","outlined":"","dense":"","hide-details":"","label":`Choose Playables (Max ${
                      20 -
                      adgroup.assets.media_bundles.filter((x) => !x.selected)
                        .length
                    })`,"items":_vm.googleAssetData,"item-text":"title","item-value":"id"},model:{value:(adgroup.assets.request_media_bundles),callback:function ($$v) {_vm.$set(adgroup.assets, "request_media_bundles", $$v)},expression:"adgroup.assets.request_media_bundles"}})],1)],1)]),_c('div',{staticClass:"py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4"},[_c('h1',{staticClass:"mb-2 font-semibold text-[12px] uppercase"},[_vm._v(" 5. Videos ("+_vm._s(adgroup.assets.videos.filter((x) => !x.selected).length)+" / 20) ")]),_c('div',{staticClass:"grid grid-cols-10 gap-4"},_vm._l((adgroup.assets.videos),function(video,videoIdx){return _c('div',{key:video.resource_name},[_c('div',{staticClass:"mt-1 text-[10px] font-semibold inline-block w-full overflow-hidden whitespace-nowrap text-ellipsis text-center"},[_vm._v(" "+_vm._s(video.value.youtube_video_title)+" ")]),_c('div',{staticClass:"w-full border rounded-[3px] flex items-center justify-center p-4 cursor-pointer hover:bg-gray-100 hover:shadow",class:{
                      'text-gray-400': video.selected,
                      'text-green-600 border-green-400 shadow':
                        !video.selected,
                    },on:{"click":function($event){_vm.handleVideoSelection(
                        adgroup.id,
                        videoIdx,
                        adgroup.selectedCreatives.filter((x) => x.selected)
                          .length * 4
                      )}}},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-brand-youtube",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36","viewBox":"0 0 24 24","stroke-width":"1","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z"}}),_c('path',{attrs:{"d":"M10 9l5 3l-5 3z"}})])]),_c('div',{staticClass:"mt-1 text-[10px] flex items-center font-semibold justify-center"},[_c('span',{staticClass:"ml-1",class:{
                        'text-lime-700': video.performance_label === 'BEST',
                        'text-red-700': video.performance_label === 'LOW',
                        'text-sky-700': video.performance_label === 'GOOD',
                        'text-orange-700':
                          video.performance_label === 'LEARNING',
                      }},[_vm._v(" "+_vm._s(video.performance_label)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',{class:{
                        'text-lime-700':
                          video.policy_summary.approval_status === 'APPROVED',
                        'text-red-700':
                          video.policy_summary.approval_status ===
                          'DISAPPROVED',
                      }},[_vm._v(" "+_vm._s(video.policy_summary.approval_status)+" ")])])])}),0),(
                  adgroup.assets.inserted_videos.length >
                  20 - adgroup.assets.videos.filter((x) => !x.selected).length
                )?_c('div',{staticClass:"rounded font-semibold p-5 text-[11px] mt-5 border mb-5",class:{
                  'bg-sky-50 text-sky-800 border-sky-200':
                    adgroup.assets.inserted_videos.length <=
                    20 -
                      adgroup.assets.videos.filter((x) => !x.selected).length,
                  'bg-red-50 text-red-800 border-red-200':
                    adgroup.assets.inserted_videos.length >
                    20 -
                      adgroup.assets.videos.filter((x) => !x.selected).length,
                }},[_vm._v(" "+_vm._s(adgroup.assets.inserted_videos.length)+" video(s) selected. You can not insert more than "+_vm._s(20 - adgroup.assets.videos.filter((x) => !x.selected).length)+" video(s). ")]):_vm._e(),_c('div',{staticClass:"mt-5 flex items-center"},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","label":`Add Videos to Replace (Max: ${
                    20 -
                    adgroup.assets.videos.filter((x) => !x.selected).length
                  })`,"outlined":"","auto-select-first":"","dense":"","items":_vm.generalMedias,"hide-details":"","item-text":"title","item-value":"id","multiple":""},model:{value:(adgroup.assets.inserted_videos),callback:function ($$v) {_vm.$set(adgroup.assets, "inserted_videos", $$v)},expression:"adgroup.assets.inserted_videos"}}),_c('div',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){adgroup.assets.inserted_videos = _vm.generalMedias.map(
                        (x) => x.id
                      )}}},[_vm._v(" Select All ("+_vm._s(_vm.generalMedias.length)+") ")])],1)],1)]),_c('ImageSelectionDialog',{attrs:{"show":_vm.showImageSelectionDialog,"images":_vm.googleImages,"game_id":_vm.game_id,"current_selected_images":JSON.parse(JSON.stringify(adgroup.assets.request_images))},on:{"closed":_vm.closeImageSelectionDialog,"selected":_vm.handleOnImageSelect}})],1):_vm._e()])]):_vm._e()])}),0),_c('div',{staticClass:"flex justify-end items-center my-3"},[_c('v-btn',{staticClass:"font-semibold",attrs:{"color":"primary","depressed":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn ||
        !this.adgroupData.filter((x) => x.assets).length ||
        _vm.isBtnDisabled},on:{"click":_vm.previewRequestData}},[_vm._v(" Replace Creatives ")])],1),_c('SummaryDialog',{attrs:{"show":_vm.showSummary,"data":_vm.computedDifferenceData,"selectedCreatives":_vm.selectedCreatives},on:{"closed":function($event){_vm.showSummary = false},"submitted":_vm.replaceCreatives}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"},[_c('span',[_vm._v(" Select Creatives")])])
}]

export { render, staticRenderFns }