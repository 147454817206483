<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card :loading="loading">
        <v-card-title class="text-h5"> Select Images </v-card-title>

        <v-card-text>
          <div class="pt-5">
            <div class="w-[330px]">
              <v-text-field
                label="Search..."
                outlined
                v-model="search"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
          </div>
          <div class="py-5">
            <v-divider></v-divider>
          </div>
          <div v-if="!googleImagesLoading">
            <div
              v-for="(image, index) in data"
              :key="index"
              class="inline-block mr-4 mb-2 relative p-1.5 hover:bg-gray-100 rounded-[5px] cursor-pointer pb-[52px]"
              @click="selectImage(image)"
              :class="{
                'bg-green-100 hover:bg-green-100': selectedImages.some(
                  (x) => x.id === image.id
                ),
              }"
            >
              <div class="text-gray-500 mb-1 text-[10px] w-full text-right">
                {{ getRelativeDate(image.created_at) }}
              </div>
              <div
                class="inline-block rounded-[3px] border p-1.5 cursor-pointer hover:bg-gray-100"
              >
                <img
                  :src="image.signed_url"
                  class="rounded-[3px] h-[110px] w-auto"
                />
              </div>

              <div class="w-full absolute mt-1 px-1 text-[11px]">
                <div class="text-gray-800 truncate">{{ image.filename }}</div>
                <div class="text-gray-500 mt-1 truncate">
                  {{ getImageRatio(image.filename) }}
                </div>
              </div>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                 
                </template>
                <span class="text-[11px]">{{ image.filename }}</span>
              </v-tooltip> -->
            </div>
          </div>
          <div v-else>
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      search: "",
      googleImagesLoading: false,
      selectedImages: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
    current_selected_images: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.selectedImages = [];
      }
    },
    images: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
    search: _.debounce(async function () {
      if (this.game_id) {
        await this.fetchGoogleImages(this.game_id);
      }
    }, 1000),
    current_selected_images: {
      deep: true,
      handler(newValue) {
        this.selectedImages = newValue ? newValue : [];
      },
    },
  },
  methods: {
    selectImage(image) {
      const index = this.selectedImages.findIndex((x) => x.id === image.id);
      if (index > -1) {
        this.selectedImages.splice(index, 1);
      } else {
        this.selectedImages.push(image);
      }

      this.$emit("selected", JSON.parse(JSON.stringify(this.selectedImages)));
    },
    async fetchGoogleImages() {
      try {
        this.googleImagesLoading = true;
        let url = `campaign-manager/google-images?game_id=${this.game_id}`;
        if (this.search) {
          url += `&title=${this.search}`;
        }

        const request = await this.$api.get(url);
        this.data = request.data;
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.googleImagesLoading = false;
      }
    },
    getRelativeDate(unix) {
      return moment.unix(unix).fromNow();
    },
    getImageRatio(filename) {
      // Define valid image extensions
      const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|webp|svg)$/i;

      // Check if the filename has a valid image extension
      if (!imageExtensions.test(filename)) {
        return "Invalid file type (not an image)";
      }

      // Check for Horizontal (1.91:1)
      if (/1_91_1\.[a-zA-Z0-9]+$/.test(filename)) {
        return "Horizontal (1.91:1)";
      }
      // Check for Square (1:1)
      else if (/1_1\.[a-zA-Z0-9]+$/.test(filename)) {
        return "Square (1:1)";
      }
      // Check for Vertical (4:5)
      else if (/4_5\.[a-zA-Z0-9]+$/.test(filename)) {
        return "Vertical (4:5)";
      }
      // If no pattern matches
      else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
