export const tools = {
  FACEBOOK_CAMPAIGN_CREATOR: 1,
  TIKTOK_CAMPAIGN_CREATOR: 2,
  ACT: 3,
  GAME_IDEA_CREATOR: 4,
  ACG: 5,
  STREAMLIT: 6,
  PARASUT: 7,
  PERSEPHONE: 8,
  APPLOVIN: 9,
  FIREBASE_AB_TESTS: 10,
  CAMPAIGN_MANAGER: 11,
  GAME_BACKEND: 12,
  CPA_SHEET: 13,
  IAP_PRICE_OPERATOR: 14,
  REFERENCE_NETWORK_MANAGEMENT: 15,
};
