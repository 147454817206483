<template>
  <div>
    <v-dialog v-model="visible" width="600" persistent>
      <v-card :loading="loading">
        <v-card-title class="text-h5"> Create New Network </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div>
              <v-text-field
                label="Network Name Origin"
                outlined
                v-model="data.network_name_origin"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Network Name"
                outlined
                v-model="data.network_name"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Network Type"
                outlined
                v-model="data.network_type"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="SK Partner Name"
                outlined
                v-model="data.sk_partner_name"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Billing Type"
                outlined
                v-model="data.billing_type"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-select
                label="Is Cpa"
                outlined
                v-model="data.is_cpa"
                dense
                hide-details
                class="text-[13px]"
                :items="['True', 'False']"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Cpa Conversion Window"
                outlined
                v-model="data.cpa_conversion_window"
                dense
                hide-details
                class="text-[13px]"
                type="number"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Dimension"
                outlined
                v-model="data.dimension"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-[13px]"
                    label="Bid Change Time"
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    v-model="data.bid_change_time"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="start_date_menu = false"
                  v-model="data.bid_change_time"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mt-3">
              <v-select
                label="Dc Status"
                outlined
                v-model="data.dc_status"
                dense
                hide-details
                class="text-[13px]"
                :items="statusItems"
                item-value="value"
                item-text="label"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-select
                label="Fb Status"
                outlined
                v-model="data.fb_status"
                dense
                hide-details
                class="text-[13px]"
                :items="statusItems"
                item-value="value"
                item-text="label"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Cost Reporting"
                outlined
                v-model="data.cost_reporting"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Event Completion Rate"
                outlined
                v-model="data.event_completion_rate"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-text-field
                label="Cost Date"
                outlined
                v-model="data.cost_date"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn
              color="red darken-1"
              :disabled="loading"
              text
              @click="visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="submitCreateButton"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: {
        network_name_origin: null,
        network_name: null,
        network_type: null,
        sk_partner_name: null,
        billing_type: null,
        is_cpa: null,
        cpa_conversion_window: null,
        dimension: null,
        bid_change_time: null,
        dc_status: null,
        fb_status: null,
        cost_reporting: null,
        event_completion_rate: null,
        cost_date: null,
      },
      statusItems: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Paused",
          value: "paused",
        },
        {
          label: "-",
          value: "-",
        },
      ],
      start_date_menu: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    clearData() {
      const keys = Object.keys(this.data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        this.data[key] = null;
      }
    },
    async submitCreateButton() {
      try {
        const data = { ...this.data };
        data["is_cpa"] = this.data.is_cpa === "True" ? 1 : 0;

        this.loading = true;
        const response = await this.$api.post(
          "utils/reference-networks/create",
          data
        );
        this.visible = false;
        this.clearData();
        this.$emit("create", response.data);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
