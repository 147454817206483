<template>
  <div class="" v-if="getUserProfile">
    <div class="p-6 bg-white">
      <h1 class="font-semibold text-[18px]">Users</h1>
    </div>
    <div class="p-6">
      <div class="p-4 bg-white">
        <div>
          <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            :loading="loading"
          >
            <template v-slot:[`item.department_id`]="{ item }">
              <v-chip
                :color="getColor(item.department_id)"
                label
                x-small
                text-color="white"
                class="font-semibold"
              >
                {{ item.department ? item.department.title : "-" }}
              </v-chip>
            </template>

            <template v-slot:[`item.tools`]="{ item }">
              <div v-if="item.tools.length > 0">
                <v-chip
                  v-for="x in item.tools"
                  :key="x.id"
                  label
                  x-small
                  text
                  color="primary"
                  class="font-semibold mr-2"
                >
                  {{ x.title }}
                </v-chip>
              </div>
              <span v-else>-</span>
            </template>

            <template v-slot:[`item.full_name`]="{ item }">
              <div class="flex items-center">
                <v-avatar size="36">
                  <img :src="item.photo" />
                </v-avatar>
                <span class="ml-2">
                  {{ item.full_name }}
                </span>
              </div>
            </template>

            <template v-slot:[`item.is_active`]="{ item }">
              <v-simple-checkbox
                v-model="item.is_active"
                disabled
              ></v-simple-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <router-link
                  :to="{ name: 'edit-user', params: { id: item.id } }"
                >
                  Edit
                </router-link>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminUsersView",
  data() {
    return {
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "User", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Tools", value: "tools", sortable: false },
        { text: "Group", value: "department_id" },
        { text: "Active", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: true,
      show_update_user: false,
      user_data: null,
    };
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      this.$api
        .get("admin/users/")
        .then((response) => {
          this.users = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getColor(id) {
      if (id === 1) return "primary";
      else if (id === 2) return "red";
      else return "green";
    },
    edit(item) {
      this.user_data = item;
      this.show_update_user = true;
    },
    handleUpdateDrawerClosed() {
      this.user_data = null;
      this.show_update_user = false;
    },
    handleUserUpdate() {
      this.fetchUsers();
    },
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .elevation-1 {
  box-shadow: none !important;
}
</style>
