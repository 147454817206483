<template>
  <div>
    <div class="bg-white p-6 w-full">
      <div class="flex items-center justify-between pb-6">
        <h1 class="font-semibold text-[18px]">CPA Logs</h1>
        <div class="flex items-center justify-end">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                small
                depressed
                class="font-semibold mr-2"
                text
                v-bind="attrs"
                v-on="on"
              >
                Import CSV
              </v-btn>
            </template>
            <div class="bg-white p-4">
              <h1 class="font-semibold text-[11px] text-gray-700 mb-3">
                Select a CSV File.
              </h1>
              <div class="w-[320px] mt-3">
                <v-file-input
                  show-size
                  accept=".csv"
                  label="CSV File"
                  class="text-[13px]"
                  outlined
                  dense
                  hide-details
                  v-model="selectedCsvFile"
                >
                </v-file-input>

                <div class="mt-3 flex justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        small
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        class="font-semibold mr-2"
                        text
                        :disabled="!selectedCsvFile"
                        @click="submitCsvFile"
                        :loading="csvButtonLoading"
                      >
                        Submit
                      </v-btn>
                    </template>
                    <span class="text-[10px]"
                      >Selected CSV file will be immediately submitted when
                      clicking the button.</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </div>
          </v-menu>

          <v-btn
            color="success"
            small
            depressed
            class="font-semibold mr-2"
            text
            @click="exportAsCSV"
          >
            Export as CSV
          </v-btn>
          <v-btn
            color="success"
            small
            depressed
            class="font-semibold mr-2"
            text
            @click="insertNewRow"
          >
            New
          </v-btn>
          <v-btn
            color="primary"
            small
            depressed
            class="font-semibold"
            @click="applyChanges"
            :loading="btnLoading"
            :disabled="btnLoading || isApplyBtnDisabled"
          >
            Apply Changes
          </v-btn>
        </div>
      </div>

      <div
        v-if="
          !networksLoading &&
          !countriesLoading &&
          !gamesLoading &&
          !conversionEventsLoading
        "
      >
        <ag-grid-vue
          style="width: 100%; height: 900px"
          class="ag-theme-quartz"
          :columnDefs="columnDefs"
          :rowData="cpaData"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          @cellClicked="onCellEditingStarted"
          @cellValueChanged="onCellValueChanged"
        >
        </ag-grid-vue>

        <div class="pt-5" v-if="cpaData.length">
          <template>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="onPaginationChange"
                :total-visible="10"
              ></v-pagination>
            </div>
          </template>
        </div>

        <div
          class="px-4 pt-6 text-gray-500 text-[12px] flex items-center justify-center"
          v-else-if="!cpaData.length"
        >
          There is nothing to show.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

import CustomButtonComponent from "@/components/tools/cpa_data/CustomButtonComponent.vue";
import CustomSelectComponent from "@/components/tools/cpa_data/CustomSelectComponent.vue";
import CustomCampaignAdgroupSelectComponent from "@/components/tools/cpa_data/CustomCampaignAdgroupSelectComponent.vue";
import CustomSelectRenderer from "@/components/tools/cpa_data/CustomSelectRenderer.vue";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  data() {
    return {
      csvButtonLoading: false,
      selectedCsvFile: null,
      csvData: null,
      fetchCpaDataInterval: null,
      page: 1,
      pageCount: 1,
      limit: 200,
      conversionEvents: {},
      cpaData: [],
      originalCpaData: [],
      games: [],
      tableLoading: false,
      btnLoading: false,
      headers: [
        {
          title: "Game",
          id: "game",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Network",
          id: "network",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Country",
          id: "country",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Campaign",
          id: "campaign",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Adgroup",
          id: "adgroup",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Cpa",
          id: "cpa",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Action Type",
          id: "action_type",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Operation Type",
          id: "operation_type",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Conversion Event",
          id: "conversion_event",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Adjust Token",
          id: "adjust_token",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Bid Value",
          id: "bid_value",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
        {
          title: "Action Date",
          id: "action_date",
          showFilter: false,
          filterData: {
            sortMode: null,
            condition: {
              mode: null,
              value: null,
            },
            selectedValues: [],
          },
        },
      ],
      sortData: [],
      filterData: [],
      countries: [],
      networks: [],
      virtualData: {},
      customButtonComponent: CustomButtonComponent,
      columnDefs: [
        {
          field: "game",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: [],
          },
          cellEditor: "CustomSelectComponent",
          cellStyle: {
            textTransform: "capitalize",
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "platform",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: {
              data: ["android", "ios"],
            },
          },
          cellEditor: "CustomSelectComponent",
          cellStyle: {
            textTransform: "capitalize",
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "network",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: [],
          },
          cellEditor: "CustomSelectComponent",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "country",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: [],
            allowTyping: true,
          },
          cellEditor: "CustomSelectComponent",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "campaign",
          filter: true,
          editable: true,
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
          cellEditorParams: {
            values: {
              data: [],
              type: "campaign",
            },
          },
          cellEditor: "CustomCampaignAdgroupSelectComponent",
        },
        {
          field: "adgroup",
          filter: true,
          editable: true,
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
          cellEditorParams: {
            values: {
              data: [],
              type: "adgroup",
            },
          },
          cellEditor: "CustomCampaignAdgroupSelectComponent",
        },
        {
          field: "campaign_type",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: {
              data: ["Multi CPA", "CPA"],
            },
          },
          cellEditor: "CustomSelectComponent",
          headerName: "Campaign Type",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "action_type",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: {
              data: ["Turn On", "Turn Off", "Bid Update", "Target Geo Update"],
            },
          },
          cellEditor: "CustomSelectComponent",
          headerName: "Action Type",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
          // eslint-disable-next-line
          comparator: () => {},
        },
        {
          field: "operation_type",
          filter: true,
          editable: true,
          cellEditorParams: {
            values: {
              data: ["None", "Decrease", "Increase"],
            },
          },
          cellEditor: "CustomSelectComponent",
          headerName: "Operation Type",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "conversion_event",
          filter: true,
          editable: true,
          headerName: "Conversion Event",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "adjust_token",
          filter: true,
          editable: false,
          suppressCellFocus: true,
          headerName: "Adjust Token",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "bid_value",
          filter: true,
          editable: true,
          headerName: "Bid Value",
          cellStyle: {
            fontSize: "13px",
          },
          cellDataType: "number",
          suppressMovable: true,
        },
        {
          field: "action_date",
          filter: true,
          editable: true,
          headerName: "Action Date",
          cellStyle: {
            fontSize: "13px",
          },
          suppressMovable: true,
        },
        {
          field: "actions",
          headerName: "Actions",
          cellRenderer: "CustomButtonComponent",
          cellRendererParams: {
            duplicated: this.handleOnDuplicateRow.bind(this),
            deleted: this.handleOnDeleteRow.bind(this),
          },
          suppressMovable: true,
        },
      ],
      gridApi: null,
      gridOptions: {
        rowClassRules: {
          // apply green to 2008
          "bg-blue-50": (params) => {
            return params.data.mode === "update";
          },

          "bg-red-50": (params) => {
            return params.data.mode === "delete";
          },

          "bg-lime-50": (params) => {
            return params.data.mode === "insert";
          },
        },
        getRowId: (params) => params.data.uuid,
        autoSizeStrategy: {
          type: "fitCellContents",
        },
        postSortRows: (params) => {
          const rowNodes = params.nodes;
          // here we put rows on top while preserving the sort order
          let nextInsertPos = 0;
          for (let i = 0; i < rowNodes.length; i++) {
            const mode = rowNodes[i].data ? rowNodes[i].data.mode : undefined;

            if (mode === "insert") {
              rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
              nextInsertPos++;
            }
          }
        },
      },
      gamesLoading: false,
      conversionEventsLoading: false,
      countriesLoading: false,
      networksLoading: false,
      campaignData: {},
      adgroupData: {},
    };
  },
  watch: {
    cpaData: {
      deep: true,
      handler() {
        if (this.fetchCpaDataInterval) {
          clearInterval(this.fetchCpaDataInterval);
          this.fetchCpaDataInterval = null;
        }
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchCpaDataInterval);
  },
  async mounted() {
    this.fetchGames();
    this.fetchConversionEvents();
    this.fetchCountries();
    this.fetchNetworks();

    if (!this.fetchCpaDataInterval) {
      this.fetchCpaDataInterval = setInterval(async () => {
        if (!this.tableLoading) {
          await this.fetchCpaData(this.limit, this.page);
        }
      }, 1000);
    }
  },

  methods: {
    ...mapActions([
      "setNetworks",
      "setCountries",
      "setGames",
      "setConversionEvents",
    ]),
    async submitCsvFile() {
      const csvData = await this.$csvFileToJson(this.selectedCsvFile);
      const createRequestData = {
        data: csvData.map((x) => ({
          ...x,
          action_date: moment(x.action_date, "YYYY-MM-DD")
            .utc(true)
            .toISOString(),
          bid_value: x.bid_value ? parseFloat(x.bid_value) : null,
        })),
      };

      try {
        this.csvButtonLoading = true;
        await this.$api.post("cpa-sheet/bulk-create", createRequestData);

        this.page = 1;
        await this.fetchCpaData(this.limit, this.page);
        this.$toast.success("Csv successfully imported.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.csvButtonLoading = false;
      }
    },
    async onPaginationChange($event) {
      await this.fetchCpaData(this.limit, $event);
    },
    insertNewRow() {
      const data = {
        game: "",
        platform: "",
        network: "",
        adgroup: "",
        country: "",
        campaign: "",
        campaign_type: "",
        action_type: "",
        operation_type: "",
        conversion_event: "",
        adjust_token: "",
        bid_value: 0,
        action_date: null,
        mode: "insert",
        uuid: this.generateUUID(),
      };

      const idx = 0;

      this.gridApi.applyTransaction({
        add: [data],
        addIndex: idx,
      });

      this.gridApi.ensureIndexVisible(idx);
      this.cpaData.push(data);
    },

    onShortChanged($event) {
      const column = $event.columns[0];
      const inserted_rows = [];
      const otherRows = [];

      for (let i = 0; i < this.cpaData.length; i++) {
        const row = this.cpaData[i];
        if (row.mode === "insert") {
          inserted_rows.push({ row, index: i });
        } else {
          otherRows.push(row);
        }
      }

      otherRows.sort((a, b) => {
        if (column.sort === "asc") {
          return a[column.colId] === b[column.colId]
            ? 0
            : a[column.colId] > b[column.colId]
            ? 1
            : -1;
        }

        if (column.sort === "desc") {
          return a[column.colId] === b[column.colId]
            ? 0
            : a[column.colId] > b[column.colId]
            ? -1
            : 1;
        }
      });

      for (let i = 0; i < inserted_rows.length; i++) {
        const element = inserted_rows[i];
        const row = JSON.parse(JSON.stringify(element));
        delete row.index;

        otherRows.splice(element.index, 0, row);
      }

      this.cpaData = otherRows;
      this.gridApi.redrawRows();
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },

    handleOnDuplicateRow($event) {
      let idx = null;

      this.gridApi.forEachNode((rowNode) => {
        if (rowNode.id === $event) {
          idx = rowNode.rowIndex;
        }
      });
      if (idx === null) return;
      const cpaIndex = this.cpaData.findIndex((x) => x.uuid === $event);

      const row = this.cpaData[cpaIndex];
      const data = {
        game: row.game,
        platform: row.platform,
        network: row.network,
        country: row.country,
        campaign: row.campaign,
        adgroup: row.adgroup,
        campaign_type: row.campaign_type,
        action_type: row.action_type,
        operation_type: row.operation_type,
        conversion_event: row.conversion_event,
        adjust_token: row.adjust_token,
        bid_value: null,
        action_date: row.action_date,
        mode: "insert",
        uuid: this.generateUUID(),
      };

      this.gridApi.applyTransaction({
        add: [data],
        addIndex: idx + 1,
      });

      this.gridApi.ensureIndexVisible(idx + 1);
      this.cpaData.splice(cpaIndex + 1, 0, data);
    },

    handleOnDeleteRow($event) {
      let idx = null;

      this.gridApi.forEachNode((rowNode) => {
        if (rowNode.id === $event) {
          idx = rowNode.rowIndex;
        }
      });
      if (idx === null) return;
      const cpaIndex = this.cpaData.findIndex((x) => x.uuid === $event);
      let mode = this.cpaData[cpaIndex].mode === "delete" ? null : "delete";

      if (!mode) {
        // Burada önceki modun update olup olmadığı kontrol edilmeli.
        const data = JSON.parse(JSON.stringify(this.cpaData[cpaIndex]));
        const originalData = JSON.parse(
          JSON.stringify(this.originalCpaData[cpaIndex])
        );

        delete data.mode;
        delete data.locked;

        delete originalData.mode;
        delete originalData.locked;

        // Eğer farklılık var ise;
        if (!_.isEqual(data, originalData)) {
          mode = "update";
        }
      }

      let row = this.gridApi.getDisplayedRowAtIndex(idx);

      // Eğer mod insert ise direkt silinebilir.
      if (this.cpaData[cpaIndex].mode === "insert") {
        this.gridApi.applyTransaction({
          remove: [{ uuid: row.data.uuid }],
        });

        this.gridApi.redrawRows();

        this.cpaData.splice(cpaIndex, 1);
        return;
      }

      this.$set(this.cpaData[cpaIndex], "mode", mode);
      this.gridApi.redrawRows({ rowNodes: [row] });
    },

    onCellValueChanged(params) {
      const cpaIndex = this.cpaData.findIndex(
        (x) => x.uuid === params.data.uuid
      );

      // Eğer insert ya da delete ise kontrol etmemize gerek yok.
      if (!["insert", "delete"].includes(this.cpaData[cpaIndex].mode)) {
        const data = JSON.parse(JSON.stringify(this.cpaData[cpaIndex]));
        const originalData = JSON.parse(
          JSON.stringify(this.originalCpaData[cpaIndex])
        );

        delete data.mode;
        delete data.locked;

        delete originalData.mode;
        delete originalData.locked;

        // Eğer farklılık var ise;
        if (!_.isEqual(data, originalData)) {
          this.$set(this.cpaData[cpaIndex], "mode", "update");
          let row = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
          this.gridApi.redrawRows({ rowNodes: [row] });
        }
        // Eğer farklılık yok ise;
        else {
          this.$set(this.cpaData[cpaIndex], "mode", null);
          let row = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
          this.gridApi.redrawRows({ rowNodes: [row] });
        }
      }

      if (
        params.colDef.field === "conversion_event" &&
        this.getConversionEvents[params.data.game]
      ) {
        const idx = this.getConversionEvents[params.data.game].findIndex(
          (x) => x.adjust_event_name == params.value
        );
        if (idx > -1) {
          params.data.adjust_token =
            this.getConversionEvents[params.data.game][idx].adjust_event_token;
          this.gridApi.refreshCells({ columns: ["adjust_token"] });

          this.cpaData[cpaIndex].adjust_token =
            this.getConversionEvents[params.data.game][idx].adjust_event_token;
        }
      }

      if (params.colDef.field === "game") {
        params.data.conversion_event = "";
        params.data.adjust_token = "";

        this.gridApi.refreshCells({
          columns: ["conversion_event", "adjust_token"],
        });
      }

      this.$set(this.cpaData[cpaIndex], params.colDef.field, params.value);
    },

    onCellEditingStarted(params) {
      const cpaIndex = this.cpaData.findIndex(
        (x) => x.uuid === params.data.uuid
      );

      if (this.cpaData[cpaIndex]) {
        const gameTitle = params.data.game;
        if (
          gameTitle &&
          this.getConversionEvents &&
          this.getConversionEvents[gameTitle]
        ) {
          const options = this.getConversionEvents[gameTitle].map(
            (x) => x.adjust_event_name
          );

          const idx = this.columnDefs.findIndex(
            (x) => x.field === "conversion_event"
          );
          if (idx > -1) {
            this.columnDefs[idx].cellEditor = "CustomSelectComponent";
            this.columnDefs[idx].cellEditorParams = {
              values: {
                data: options,
              },
            };

            this.gridApi.setGridOption("columnDefs", this.columnDefs);
          }
        } else {
          const idx = this.columnDefs.findIndex(
            (x) => x.field === "conversion_event"
          );
          if (idx > -1) {
            this.columnDefs[idx].cellEditor = "CustomSelectComponent";
            this.columnDefs[idx].cellEditorParams = {
              values: {
                data: [],
              },
            };

            this.gridApi.setGridOption("columnDefs", this.columnDefs);
          }
        }

        // if (!this.campaignData[gameTitle]) {
        //   await this.fetchGameCampaignData(gameTitle);
        // }

        // if (!this.adgroupData[gameTitle]) {
        //   await this.fetchGameAdgroupData(gameTitle);
        // }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;

      if (this.getNetworks && this.getNetworks.length) {
        const idx = this.columnDefs.findIndex((x) => x.field === "network");
        if (idx > -1) {
          this.columnDefs[idx].cellEditorParams.values = {
            data: this.getNetworks.map((x) => x.network_name_origin),
          };
        }
      }

      if (this.getGames && this.getGames.length) {
        const idx = this.columnDefs.findIndex((x) => x.field === "game");
        if (idx > -1) {
          this.columnDefs[idx].cellEditorParams.values = {
            data: this.getGames.map((x) => x.title.toLowerCase()),
          };
        }
      }

      if (this.getCountries && this.getCountries.length) {
        const idx = this.columnDefs.findIndex((x) => x.field === "country");
        if (idx > -1) {
          this.columnDefs[idx].cellEditorParams.values = {
            data: this.getCountries.map((x) => ({
              iso_lower: x.iso_lower,
              name: x.name,
            })),
            label: "name",
            value: "iso_lower",
          };
        }
      }

      if (this.getConversionEvents && this.getConversionEvents.length) {
        const idx = this.columnDefs.findIndex(
          (x) => x.field === "conversion_event"
        );
        if (idx > -1) {
          this.columnDefs[idx].cellEditorParams.values =
            this.getConversionEvents.map((x) => x.adjust_event_token);
        }
      }

      this.gridApi.setGridOption("columnDefs", this.columnDefs);
    },
    exportAsCSV() {
      const copiedCpaData = [...this.originalCpaData];
      for (let i = 0; i < copiedCpaData.length; i++) {
        delete copiedCpaData[i].locked;
        delete copiedCpaData[i].is_deleted;

        if (copiedCpaData[i].originalData) {
          delete copiedCpaData[i].originalData;
        }
      }

      const titleKeys = Object.keys(copiedCpaData[0]);
      const refinedData = [];

      refinedData.push(titleKeys);

      copiedCpaData.forEach((item) => {
        refinedData.push(Object.values(item));
      });

      let csvContent = "";

      refinedData.forEach((row) => {
        csvContent += row.join(";") + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objUrl);
      link.setAttribute(
        "download",
        `CPA_SHEET_${moment().format("DD_MM_YYYY_HH_MM_SS")}`
      );
      link.click();
    },
    async fetchConversionEvents() {
      try {
        this.conversionEventsLoading = true;
        const response = await this.$api.get("cpa-sheet/conversion-events");
        var data = {};
        data = response.data;

        if (Object.keys(data).length) {
          const keys = Object.keys(data);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            data[key] = this.$sortArrayByProperty(
              data[key],
              ["adjust_event_name"],
              ["asc"]
            );
          }
        }
        this.setConversionEvents(data);
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.conversionEventsLoading = false;
      }
    },

    async fetchCountries() {
      if (this.getCountries.length) return;
      try {
        this.countriesLoading = true;
        const response = await this.$api.get("cpa-sheet/countries");
        this.setCountries(response.data);
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.countriesLoading = false;
      }
    },

    async fetchNetworks() {
      if (this.getNetworks.length) return;
      try {
        this.networksLoading = true;
        const response = await this.$api.get("cpa-sheet/networks");
        this.setNetworks(response.data);

        const idx = this.columnDefs.findIndex((x) => x.field === "network");
        if (idx > 0) {
          this.columnDefs[idx].cellEditorParams.values = response.data.map(
            (x) => x.network_name_origin
          );
        }
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.networksLoading = false;
      }
    },

    getAvailableFilteringValues(id) {
      return [...new Set(this.originalCpaData.map((x) => x[id]))];
    },

    getHeadersWithoutShowFilter(data) {
      return JSON.parse(
        JSON.stringify(
          data.map((x) => {
            // eslint-disable-next-line
            return (({ showFilter, ...o }) => o)(x);
          })
        )
      );
    },

    fetchGames() {
      if (this.getGames.length) return;
      this.gamesLoading = true;
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.setGames(response.data);
          this.gamesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.gamesLoading = false;
        });
    },

    async applyChanges() {
      try {
        this.btnLoading = true;

        // Modu insert olanlar BQ'ye eklenmeli.
        if (this.cpaData.filter((x) => x.mode === "insert").length) {
          const createRequestData = {
            data: this.cpaData
              .filter((x) => x.mode === "insert")
              .map((x) => ({
                ...x,
                action_date: moment(x.action_date, "YYYY-MM-DD")
                  .utc(true)
                  .toISOString(),
              })),
          };

          const response = await this.$api.post(
            "cpa-sheet/bulk-create",
            createRequestData
          );
          if (response.status === 200 && response.data.success) {
            const rows = response.data.success.map((x) => ({
              ...x,
              locked: true,
              action_date: moment(x.action_date).format("YYYY-MM-DD"),
            }));
            this.resetInsertRows(rows);
            this.showSuccessMessage(`Rows successfully inserted.`);
          }
        }

        // Modu update olanlar BQ'de güncellenmeli.
        // Güncellendikten sonra response'den dönen ID bilgisi ile modları resetleyelim.
        if (this.cpaData.filter((x) => x.mode === "update").length) {
          const updateRequestData = JSON.parse(
            JSON.stringify({
              data: this.cpaData
                .filter((x) => x.mode === "update")
                .map((x) => ({
                  ...x,
                  action_date: moment(x.action_date, "YYYY-MM-DD")
                    .utc(true)
                    .toISOString(),
                  originalData: this.originalCpaData.find(
                    (node) => node.uuid === x.uuid
                  ),
                })),
            })
          );

          for (let i = 0; i < updateRequestData.data.length; i++) {
            if (updateRequestData.data[i].originalData) {
              updateRequestData.data[i].originalData.action_date = moment(
                updateRequestData.data[i].originalData.action_date,
                "YYYY-MM-DD"
              )
                .utc(true)
                .toISOString();
            }
          }

          const response = await this.$api.post(
            "cpa-sheet/update",
            updateRequestData
          );
          if (response.status === 200 && response.data.success) {
            for (let i = 0; i < response.data.success.length; i++) {
              const element = response.data.success[i];
              this.updateOriginalDataById(element);
              this.resetModeById(element, "update");
            }

            this.gridApi.redrawRows();

            this.showSuccessMessage(
              `${response.data.success.length} rows successfully updated.`
            );
          }
        }

        // Modu delete olanlar BQ'de silinmeli.
        // Güncellendikten sonra response'den dönen ID bilgisi ile cpaData'dan silelim.
        if (this.cpaData.filter((x) => x.mode === "delete").length) {
          const deleteRequestData = {
            data: this.cpaData
              .filter((x) => x.mode === "delete")
              .map((x) => ({
                ...x,
                action_date: moment(x.action_date, "YYYY-MM-DD")
                  .utc(true)
                  .toISOString(),
              })),
          };

          const response = await this.$api.post(
            "cpa-sheet/delete",
            deleteRequestData
          );
          if (response.status === 200 && response.data.success) {
            for (let i = 0; i < response.data.success.length; i++) {
              const element = response.data.success[i];
              this.spliceRowsById(element);

              this.gridApi.applyTransaction({
                remove: [{ uuid: element }],
              });
            }

            this.gridApi.redrawRows();

            this.showSuccessMessage(
              `${response.data.success.length} rows successfully deleted.`
            );
          }
        }
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.btnLoading = false;
      }
    },

    spliceRowsById(id) {
      const index = this.cpaData.findIndex((x) => x.uuid === id);
      if (index > -1) {
        this.cpaData.splice(index, 1);
      }
    },

    updateOriginalDataById(id) {
      const indexOriginal = this.originalCpaData.findIndex(
        (x) => x.uuid === id
      );
      const indexCpa = this.cpaData.findIndex((x) => x.uuid === id);

      if (indexOriginal > -1 && indexCpa > -1) {
        this.originalCpaData[indexOriginal] = JSON.parse(
          JSON.stringify(this.cpaData[indexCpa])
        );
      }
    },

    resetInsertRows(rows) {
      this.cpaData = this.cpaData.filter((x) => x.mode !== "insert");
      this.originalCpaData = this.originalCpaData.filter(
        (x) => x.mode !== "insert"
      );

      this.cpaData = JSON.parse(JSON.stringify([...this.cpaData, ...rows]));
      this.originalCpaData = JSON.parse(
        JSON.stringify([...this.originalCpaData, ...rows])
      );

      this.gridApi.redrawRows();
    },

    resetModeById(id, mode) {
      for (let i = 0; i < this.cpaData.length; i++) {
        if (this.cpaData[i].uuid === id && this.cpaData[i].mode == mode) {
          this.$set(this.cpaData[i], "mode", null);
        }
      }
      this.gridApi.redrawRows();
    },

    async fetchCpaData(limit, page) {
      if (
        !this.networksLoading &&
        !this.countriesLoading &&
        !this.gamesLoading &&
        !this.conversionEventsLoading
      ) {
        try {
          this.tableLoading = true;
          this.gridApi.showLoadingOverlay();
          const response = await this.$api.get(
            `cpa-sheet/all?limit=${limit}&offset=${(page - 1) * limit}`
          );
          this.cpaData = response.data.data.map((x) => ({
            ...x,
            mode: null,
            locked: true,
            action_date: moment(x.action_date).format("YYYY-MM-DD"),
          }));
          this.originalCpaData = JSON.parse(JSON.stringify(this.cpaData));
          this.pageCount =
            Math.round(response.data.number_of_records / this.limit) + 1;
        } catch (error) {
          this.$globalErrorHandler(error);
        }
      }
    },

    showSuccessMessage(message) {
      this.$toast.success(message, {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });
    },
  },

  computed: {
    ...mapGetters([
      "getNetworks",
      "getCountries",
      "getGames",
      "getConversionEvents",
    ]),
    isApplyBtnDisabled() {
      for (let i = 0; i < this.cpaData.length; i++) {
        const node = this.cpaData[i];
        if (["update", "insert"].includes(node.mode)) {
          if (!node.game) {
            return true;
          }
          if (!node.network) {
            return true;
          }
          if (!node.country) {
            return true;
          }
          if (!node.campaign) {
            return true;
          }
          if (!node.campaign_type) {
            return true;
          }
          if (!node.action_type) {
            return true;
          }
          if (!node.operation_type) {
            return true;
          }
          if (!node.conversion_event) {
            return true;
          }
          if (!node.adjust_token) {
            return true;
          }
          if (!node.bid_value && node.action_type !== "Turn Off") {
            return true;
          }
          if (!node.action_date) {
            return true;
          }
        }
      }

      if (
        this.cpaData.filter((x) =>
          ["update", "insert", "delete"].includes(x.mode)
        ).length
      )
        return false;

      return true;
    },
    computedHeaders() {
      return JSON.parse(
        JSON.stringify(
          this.headers.map((x) => {
            // eslint-disable-next-line
            return (({ showFilter, ...o }) => o)(x);
          })
        )
      );
    },

    computedSortData() {
      return JSON.parse(JSON.stringify(this.sortData));
    },

    computedFilterData() {
      return JSON.parse(JSON.stringify(this.filterData));
    },
  },

  components: {
    // VirtualList,
    AgGridVue,
    CustomButtonComponent,
    CustomSelectComponent,
    CustomSelectRenderer,
    CustomCampaignAdgroupSelectComponent,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
  flex: none !important;
}

::v-deep .v-btn-toggle {
  display: block !important;
}

::v-deep .v-btn__content {
  font-size: 11px;
}

::v-deep .ag-cell {
  .ag-icon {
    vertical-align: unset !important;
  }
}
</style>
