<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Crop & Edit Image </v-card-title>

        <v-card-text>
          <div v-if="data">
            <div class="flex">
              <div
                class="max-w-[550px] w-[550px] flex flex-col items-center justify-center"
              >
                <div v-if="imageData && !imageDataLoading">
                  <cropper
                    :src="imageData.baseImageData"
                    :stencil-props="{
                      aspectRatio: imageData.variants[selectedVariant].ratio,
                    }"
                    :resizeImage="{ wheel: false }"
                    :style="{
                      width: `${
                        adjustNumbers(
                          imageData.baseImageWidth,
                          imageData.baseImageHeight
                        ).x
                      }px`,
                      height: `${
                        adjustNumbers(
                          imageData.baseImageWidth,
                          imageData.baseImageHeight
                        ).y
                      }px`,
                    }"
                    :defaultPosition="{
                      top: imageData.variants[selectedVariant].coords.top,
                      left: imageData.variants[selectedVariant].coords.left,
                    }"
                    :defaultSize="
                      calculatedDefaultSize(
                        imageData.baseImageWidth,
                        imageData.baseImageHeight,
                        imageData.variants[selectedVariant].ratio
                      )
                    "
                    @change="updateCoords"
                  ></cropper>
                </div>

                <div
                  v-else-if="imageDataLoading"
                  class="w-[550px]"
                  :style="{
                    height: `${
                      adjustNumbers(
                        tempImageData.baseImageWidth,
                        tempImageData.baseImageHeight
                      ).y
                    }px`,
                  }"
                >
                  <div>
                    <v-skeleton-loader
                      type="list-item-three-line"
                    ></v-skeleton-loader>
                  </div>

                  <div class="mt-1">
                    <v-skeleton-loader
                      type="list-item-two-line"
                    ></v-skeleton-loader>
                  </div>

                  <div class="mt-1">
                    <v-skeleton-loader
                      type="list-item-three-line"
                    ></v-skeleton-loader>
                  </div>
                </div>

                <div
                  class="mt-3 flex items-center justify-between rounded-[3px] w-full"
                  v-if="tempImageData"
                >
                  <div
                    v-for="(variant, variantIdx) in tempImageData.variants"
                    :key="variantIdx"
                    class="p-4 hover:bg-gray-50 w-full cursor-pointer flex items-center justify-center flex-col"
                    :class="{
                      'bg-lime-100':
                        variant.isIncluded && variantIdx !== selectedVariant,
                      'bg-gray-200': variantIdx === selectedVariant,
                    }"
                    @click="updateImageData(variantIdx)"
                  >
                    <img
                      class="mb-1.5 rounded-[3px]"
                      :src="variant.image"
                      :height="40"
                      :width="40 * variant.ratio"
                    />
                    <span class="font-semibold text-[11px]">
                      {{ variant.title }}
                    </span>

                    <div class="mt-0.5" @click.stop>
                      <v-checkbox
                        dense
                        v-model="variant.isIncluded"
                        hide-details
                        label="Include"
                      >
                      </v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-4">
                <h1 class="text-[13px] font-semibold">Filename</h1>
                <div class="text-[12px] text-gray-600">
                  {{ data.baseImage }}
                </div>

                <h1 class="mt-2.5 text-[13px] font-semibold">Dimensions</h1>
                <div class="text-[12px] text-gray-600">
                  {{ data.baseImageWidth }}x{{ data.baseImageHeight }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveCrop"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    selectedVariantIdx: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data: {
      handler(newValue) {
        if (newValue) {
          this.imageData = JSON.parse(JSON.stringify(newValue));
          this.tempImageData = JSON.parse(JSON.stringify(newValue));
        }
      },
      deep: true,
    },
    selectedVariantIdx(newValue) {
      this.selectedVariant = newValue;
    },
  },
  data() {
    return {
      visible: false,
      imageData: null,
      tempImageData: null,
      selectedVariant: null,
      imageDataLoading: false,
    };
  },
  components: {
    Cropper,
  },
  methods: {
    saveCrop() {
      this.$emit("submit", this.tempImageData);
    },
    updateCoords($event) {
      const { coordinates, canvas } = $event;
      this.tempImageData.variants[this.selectedVariant].coords.left =
        coordinates.left;
      this.tempImageData.variants[this.selectedVariant].coords.top =
        coordinates.top;

      this.tempImageData.variants[this.selectedVariant].image =
        canvas.toDataURL("image/jpeg");
    },
    updateImageData(variantIdx) {
      if (this.selectedVariant === variantIdx) return;
      this.selectedVariant = variantIdx;
      this.imageDataLoading = true;
      this.imageData = null;
      setTimeout(() => {
        this.imageData = this.tempImageData;
        this.imageDataLoading = false;
      }, 200);
    },
    calculatedDefaultSize(width, height, ratio) {
      if (ratio === 1.91) {
        return {
          width: width,
          height: Math.round(width / ratio),
        };
      } else {
        return {
          width: Math.round(height * ratio),
          height: height,
        };
      }
    },
    adjustNumbers(x, y) {
      // Calculate the ratio between x and y
      const ratio = Math.round(x / y);

      // Set the target value for x (e.g., 500)
      const targetX = 500;

      // Calculate the adjusted value for x while maintaining the ratio
      const adjustedX = Math.min(targetX, x);

      // Calculate the corresponding adjusted value for y
      const adjustedY = Math.round(adjustedX / ratio);

      // Create and return the result object
      const result = { x: adjustedX, y: adjustedY };
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
