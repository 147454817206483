<template>
  <div>
    <canvas id="imageResizer" class="hidden"></canvas>

    <div class="filter-panel mb-5 flex items-center">
      <div class="inline-block">
        <v-text-field
          placeholder="Campaign or adgroup name..."
          outlined
          dense
          v-model="searchKey"
          append-icon="mdi-magnify"
          class="text-[13px]"
          style="width: 260px"
          hide-details
        ></v-text-field>
      </div>
      <div class="inline-block ml-2">
        <v-select
          label="Sort"
          outlined
          v-model="selectedSortType"
          :items="sortSelections"
          item-text="text"
          item-value="value"
          dense
          hide-details
          class="text-[13px]"
        ></v-select>
      </div>

      <div class="inline-block w-[500px] ml-2">
        <v-autocomplete
          clearable
          label="Campaigns"
          outlined
          auto-select-first
          dense
          v-model="filterData.campaigns"
          :items="Array.from(new Set(adgroupData.map((x) => x.campaign_name)))"
          hide-details
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="text-[12px]">
              {{ item }}
            </span>
            <span v-if="index === 1" class="grey--text text-caption ml-2">
              (+{{ filterData.campaigns.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div class="border rounded">
      <div
        v-for="adgroup in adgroupData
          .filter((x) => filterData.campaigns.includes(x.campaign_name))
          .filter(
            (x) =>
              new RegExp(filterData.searchKey, 'i').test(x.campaign_name) ||
              new RegExp(filterData.searchKey, 'i').test(x.name)
          )"
        :key="adgroup.id"
      >
        <div
          @click="handleOnPanelView(adgroup.id)"
          class="flex items-center panel-header p-4 hover:bg-gray-100 cursor-pointer"
          :class="{
            edited: isAdgroupModified(adgroup.id),
            'bg-gray-100': selectedAdsetPanel === adgroup.id,
          }"
        >
          <span class="font-semibold panel-campaign-name text-[11px]">
            {{ adgroup.campaign_name }} {{ adgroup.id }}
          </span>
          <span class="mx-2 font-semibold text-[10px]"> > </span>
          <span class="font-semibold panel-adgroup-name text-[11px]">
            {{ adgroup.name }}
          </span>
        </div>

        <div v-if="selectedAdsetPanel === adgroup.id">
          <div v-if="adgroup.loading_assets">
            <div>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </div>
          </div>

          <div v-else>
            <div v-if="adgroup.assets && adgroup.selectedCreatives">
              <div class="py-1 px-3">
                <h1
                  class="mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
                >
                  <span> Select Creatives</span>
                </h1>
                <div class="w-full my-5">
                  <div
                    class="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3"
                  >
                    <div
                      v-for="(
                        ad, selectedCreativeIdx
                      ) in adgroup.selectedCreatives"
                      :key="ad.ad_id"
                      class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                      :class="{
                        selected: ad.selected,
                      }"
                      @click="
                        handleSelectCreative(adgroup.id, selectedCreativeIdx)
                      "
                    >
                      <div class="flex items-center justify-between">
                        <span
                          class="text-[11px]"
                          :class="
                            ad.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ ad.ad_name }}
                        </span>
                        <span
                          v-if="ad.improvement"
                          class="text-[10px] ml-2"
                          :class="{
                            'font-semibold': ad.selected,
                            'text-gray-400': !ad.selected,
                            'text-lime-700': ad.improvement >= 0 && ad.selected,
                            'text-rose-700': ad.improvement < 0 && ad.selected,
                          }"
                        >
                          {{ ad.improvement.toFixed(2) }}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <v-divider class=""></v-divider>
              </div>
              <!-- Descriptions -->
              <div class="py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4">
                <h1 class="mb-2 font-semibold text-[12px] uppercase">
                  1. Descriptions
                </h1>
                <div class="grid grid-cols-3 gap-4">
                  <div
                    v-for="description in adgroup.assets.descriptions"
                    :key="description.resource_name"
                  >
                    <v-text-field
                      label="Description"
                      outlined
                      v-model="description.value"
                      dense
                      hide-details
                      maxlength="90"
                      class="text-[13px]"
                    ></v-text-field>
                    <div
                      class="mt-1 text-[10px] flex items-center font-semibold"
                    >
                      <span
                        class="ml-1"
                        :class="{
                          'text-lime-700':
                            description.performance_label === 'BEST',
                          'text-red-700':
                            description.performance_label === 'LOW',
                          'text-sky-700':
                            description.performance_label === 'GOOD',
                          'text-orange-700':
                            description.performance_label === 'LEARNING',
                        }"
                      >
                        {{ description.performance_label }}
                      </span>
                      <span class="mx-1">-</span>
                      <span
                        :class="{
                          'text-lime-700':
                            description.policy_summary.approval_status ===
                            'APPROVED',
                          'text-red-700':
                            description.policy_summary.approval_status ===
                            'DISAPPROVED',
                        }"
                      >
                        {{ description.policy_summary.approval_status }}
                      </span>
                    </div>

                    <span
                      class="text-red-600 text-[10px] font-semibold"
                      v-if="
                        adgroup.assets.descriptions.find(
                          (x) =>
                            x.id !== description.id &&
                            x.value === description.value
                        )
                      "
                      >This description has been used already in another
                      field.</span
                    >
                  </div>
                </div>
              </div>

              <!-- Headlines -->
              <div class="py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4">
                <h1 class="mb-2 font-semibold text-[12px] uppercase">
                  2. Headlines
                </h1>
                <div class="grid grid-cols-3 gap-4">
                  <div
                    v-for="headline in adgroup.assets.headlines"
                    :key="headline.resource_name"
                  >
                    <v-text-field
                      label="Headline"
                      outlined
                      v-model="headline.value"
                      dense
                      hide-details
                      maxlength="30"
                      class="text-[13px]"
                    ></v-text-field>
                    <div
                      class="mt-1 text-[10px] flex items-center font-semibold"
                    >
                      <span
                        class="ml-1"
                        :class="{
                          'text-lime-700':
                            headline.performance_label === 'BEST',
                          'text-red-700': headline.performance_label === 'LOW',
                          'text-sky-700': headline.performance_label === 'GOOD',
                          'text-orange-700':
                            headline.performance_label === 'LEARNING',
                        }"
                      >
                        {{ headline.performance_label }}
                      </span>
                      <span class="mx-1">-</span>
                      <span
                        :class="{
                          'text-lime-700':
                            headline.policy_summary.approval_status ===
                            'APPROVED',
                          'text-red-700':
                            headline.policy_summary.approval_status ===
                            'DISAPPROVED',
                        }"
                      >
                        {{ headline.policy_summary.approval_status }}
                      </span>
                    </div>

                    <span
                      class="text-red-600 text-[10px] font-semibold"
                      v-if="
                        adgroup.assets.headlines.find(
                          (x) =>
                            x.id !== headline.id && x.value === headline.value
                        )
                      "
                      >This headline has been used already in another
                      field.</span
                    >
                  </div>
                </div>
              </div>

              <!-- Images -->
              <div class="py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4">
                <h1 class="mb-2 font-semibold text-[12px] uppercase">
                  3. Images ({{
                    adgroup.assets.images.filter((x) => !x.selected).length
                  }}
                  / 20)
                </h1>
                <div
                  class="rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] border border-sky-200 mb-2"
                  v-if="adgroup.assets.images.filter((x) => x.selected).length"
                >
                  {{ adgroup.assets.images.filter((x) => x.selected).length }}
                  image(s) will be deleted.
                </div>

                <div
                  class="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3"
                >
                  <div
                    v-for="image in adgroup.assets.images"
                    :key="image.resource_name"
                    class="flex flex-col items-center justify-between hover:bg-gray-200 p-3 cursor-pointer"
                    :class="{ 'bg-gray-200': image.selected }"
                    @click="image.selected = !image.selected"
                  >
                    <div
                      class="w-full text-center text-ellipsis overflow-hidden"
                    >
                      <span class="text-[10px] font-semibold">
                        {{ image.name }}
                      </span>
                    </div>
                    <div
                      class="adgroup_image rounded-[3px] cursor-pointer"
                      :class="{
                        'contrast-[0.1]': image.selected,
                        'hover:shadow-lg hover:contrast-75': !image.selected,
                      }"
                      :style="{ backgroundImage: `url(${image.value})` }"
                    ></div>
                    <div
                      class="mt-1 text-[10px] flex items-center font-semibold"
                    >
                      <span
                        class="ml-1"
                        :class="{
                          'text-lime-700': image.performance_label === 'BEST',
                          'text-red-700': image.performance_label === 'LOW',
                          'text-sky-700': image.performance_label === 'GOOD',
                          'text-orange-700':
                            image.performance_label === 'LEARNING',
                        }"
                      >
                        {{ image.performance_label }}
                      </span>
                      <span class="mx-1">-</span>
                      <span
                        :class="{
                          'text-lime-700':
                            image.policy_summary.approval_status === 'APPROVED',
                          'text-red-700':
                            image.policy_summary.approval_status ===
                            'DISAPPROVED',
                        }"
                      >
                        {{ image.policy_summary.approval_status }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div
                    class="rounded bg-red-50 text-red-800 font-semibold p-5 text-[11px] border border-red-200 mb-2"
                    v-if="
                      adgroup.assets.images.filter((x) => !x.selected).length +
                        adgroup.assets.request_images.length >
                      20
                    "
                  >
                    You need to unselect
                    {{
                      adgroup.assets.images.filter((x) => !x.selected).length +
                      adgroup.assets.request_images.length -
                      20
                    }}
                    images.
                  </div>
                </div>

                <div v-if="adgroup.assets.request_images.length" class="mt-3">
                  <v-divider class="mb-3"></v-divider>
                  <div
                    class="inline-block p-1 bg-gray-50 mr-1.5 cursor-pointer hover:bg-gray-100"
                    v-for="(image, imageIdx) in adgroup.assets.request_images"
                    @click="removeImage(imageIdx, adgroup.id)"
                    :key="imageIdx"
                  >
                    <div class="max-w-[100px] bg-gray-50 mx-auto rounded-[3px]">
                      <img
                        :src="image.signed_url"
                        style="max-height: 50px; max-width: 95px"
                        class="rounded-[3px] mx-auto"
                      />
                    </div>
                    <span class="ml-2 text-[11px] font-semibold mx-auto">
                      {{ image.filename }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="px-3">
                <div
                  class="border rounded bg-gray-50 hover:bg-blue-100 hover:border-blue-300 hover:text-blue-600 p-4 text-[11px] font-semibold border-dashed cursor-pointer mt-4"
                  @click="clickImageSelectionDialog(adgroup)"
                >
                  + Choose Images ({{ adgroup.assets.request_images.length }}
                  selected)
                </div>
              </div>

              <!-- HTML5 -->
              <div class="py-1 px-3 rounded-[3px] mt-4">
                <h1 class="mb-2 font-semibold text-[12px] uppercase">
                  4. Playable HTML Pages ({{
                    adgroup.assets.media_bundles.filter((x) => !x.selected)
                      .length
                  }}
                  / 20)
                </h1>
                <div
                  class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3"
                >
                  <div
                    v-for="html in adgroup.assets.media_bundles"
                    :key="html.resource_name"
                    class="p-4 cursor-pointer hover:bg-gray-100"
                    :class="{
                      'bg-rose-50': html.selected,
                      'bg-green-50': !html.selected,
                    }"
                    @click="
                      () => {
                        html.selected = !html.selected;
                        adgroup.assets.request_media_bundles = [];
                      }
                    "
                  >
                    <div
                      class="w-full text-center text-ellipsis overflow-hidden"
                    >
                      <span class="text-[10px] font-semibold">
                        {{ html.name }}
                      </span>
                    </div>
                    <div
                      class="w-full rounded-[3px] flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-file-code"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                        <path
                          d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                        ></path>
                        <path d="M10 13l-1 2l1 2"></path>
                        <path d="M14 13l1 2l-1 2"></path>
                      </svg>
                    </div>
                    <div
                      class="mt-1 text-[10px] flex items-center font-semibold justify-center"
                    >
                      <span
                        class="ml-1"
                        :class="{
                          'text-lime-700': html.performance_label === 'BEST',
                          'text-red-700': html.performance_label === 'LOW',
                          'text-sky-700': html.performance_label === 'GOOD',
                          'text-orange-700':
                            html.performance_label === 'LEARNING',
                        }"
                      >
                        {{ html.performance_label }}
                      </span>
                      <span class="mx-1">-</span>
                      <span
                        :class="{
                          'text-lime-700':
                            html.policy_summary.approval_status === 'APPROVED',
                          'text-red-700':
                            html.policy_summary.approval_status ===
                            'DISAPPROVED',
                        }"
                      >
                        {{ html.policy_summary.approval_status }}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-divider class="my-5"></v-divider>
                  <div class="w-[320px]">
                    <v-combobox
                      multiple
                      outlined
                      dense
                      hide-details
                      :label="`Choose Playables (Max ${
                        20 -
                        adgroup.assets.media_bundles.filter((x) => !x.selected)
                          .length
                      })`"
                      v-model="adgroup.assets.request_media_bundles"
                      class="text-[13px]"
                      :items="googleAssetData"
                      item-text="title"
                      item-value="id"
                    ></v-combobox>
                  </div>
                </div>
              </div>

              <!-- Videos -->
              <div class="py-1 px-3 hover:bg-gray-50 rounded-[3px] mt-4">
                <h1 class="mb-2 font-semibold text-[12px] uppercase">
                  5. Videos ({{
                    adgroup.assets.videos.filter((x) => !x.selected).length
                  }}
                  / 20)
                </h1>
                <div class="grid grid-cols-10 gap-4">
                  <div
                    v-for="(video, videoIdx) in adgroup.assets.videos"
                    :key="video.resource_name"
                  >
                    <div
                      class="mt-1 text-[10px] font-semibold inline-block w-full overflow-hidden whitespace-nowrap text-ellipsis text-center"
                    >
                      {{ video.value.youtube_video_title }}
                    </div>
                    <div
                      class="w-full border rounded-[3px] flex items-center justify-center p-4 cursor-pointer hover:bg-gray-100 hover:shadow"
                      :class="{
                        'text-gray-400': video.selected,
                        'text-green-600 border-green-400 shadow':
                          !video.selected,
                      }"
                      @click="
                        handleVideoSelection(
                          adgroup.id,
                          videoIdx,
                          adgroup.selectedCreatives.filter((x) => x.selected)
                            .length * 4
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-brand-youtube"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z"
                        ></path>
                        <path d="M10 9l5 3l-5 3z"></path>
                      </svg>
                    </div>
                    <div
                      class="mt-1 text-[10px] flex items-center font-semibold justify-center"
                    >
                      <span
                        class="ml-1"
                        :class="{
                          'text-lime-700': video.performance_label === 'BEST',
                          'text-red-700': video.performance_label === 'LOW',
                          'text-sky-700': video.performance_label === 'GOOD',
                          'text-orange-700':
                            video.performance_label === 'LEARNING',
                        }"
                      >
                        {{ video.performance_label }} </span
                      ><span class="mx-1">-</span>
                      <span
                        :class="{
                          'text-lime-700':
                            video.policy_summary.approval_status === 'APPROVED',
                          'text-red-700':
                            video.policy_summary.approval_status ===
                            'DISAPPROVED',
                        }"
                      >
                        {{ video.policy_summary.approval_status }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="rounded font-semibold p-5 text-[11px] mt-5 border mb-5"
                  :class="{
                    'bg-sky-50 text-sky-800 border-sky-200':
                      adgroup.assets.inserted_videos.length <=
                      20 -
                        adgroup.assets.videos.filter((x) => !x.selected).length,
                    'bg-red-50 text-red-800 border-red-200':
                      adgroup.assets.inserted_videos.length >
                      20 -
                        adgroup.assets.videos.filter((x) => !x.selected).length,
                  }"
                  v-if="
                    adgroup.assets.inserted_videos.length >
                    20 - adgroup.assets.videos.filter((x) => !x.selected).length
                  "
                >
                  {{ adgroup.assets.inserted_videos.length }} video(s) selected.
                  You can not insert more than
                  {{
                    20 - adgroup.assets.videos.filter((x) => !x.selected).length
                  }}
                  video(s).
                </div>

                <div class="mt-5 flex items-center">
                  <v-autocomplete
                    clearable
                    :label="`Add Videos to Replace (Max: ${
                      20 -
                      adgroup.assets.videos.filter((x) => !x.selected).length
                    })`"
                    outlined
                    auto-select-first
                    dense
                    class="text-[12px]"
                    v-model="adgroup.assets.inserted_videos"
                    :items="generalMedias"
                    hide-details
                    item-text="title"
                    item-value="id"
                    multiple
                  >
                  </v-autocomplete>

                  <div class="ml-2">
                    <v-btn
                      color="primary"
                      text
                      @click="
                        adgroup.assets.inserted_videos = generalMedias.map(
                          (x) => x.id
                        )
                      "
                    >
                      Select All ({{ generalMedias.length }})
                    </v-btn>
                  </div>
                </div>
              </div>

              <ImageSelectionDialog
                :show="showImageSelectionDialog"
                @closed="closeImageSelectionDialog"
                :images="googleImages"
                :game_id="game_id"
                @selected="handleOnImageSelect"
                :current_selected_images="
                  JSON.parse(JSON.stringify(adgroup.assets.request_images))
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="previewRequestData"
        :loading="loadingBtn"
        :disabled="
          loadingBtn ||
          !this.adgroupData.filter((x) => x.assets).length ||
          isBtnDisabled
        "
      >
        Replace Creatives
      </v-btn>
    </div>

    <SummaryDialog
      :show="showSummary"
      :data="computedDifferenceData"
      :selectedCreatives="selectedCreatives"
      @closed="showSummary = false"
      @submitted="replaceCreatives"
    />
  </div>
</template>

<script>
import _ from "lodash";
import SummaryDialog from "./SummaryDialog.vue";
import ImageSelectionDialog from "./ImageSelectionDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    selectedSortType: {
      handler(newValue) {
        if (newValue === "sort-by-campaign-name-asc") {
          if (this.adgroupData && this.adgroupData.length)
            this.adgroupData = this.$sortArrayByProperty(
              this.adgroupData,
              ["campaign_name"],
              ["asc"]
            );
        } else if (newValue === "sort-by-campaign-name-desc") {
          if (this.adgroupData && this.adgroupData.length)
            this.adgroupData = this.$sortArrayByProperty(
              this.adgroupData,
              ["campaign_name"],
              ["desc"]
            );
        } else if (newValue === "sort-by-adgroup-name-asc") {
          if (this.adgroupData && this.adgroupData.length)
            this.adgroupData = this.$sortArrayByProperty(
              this.adgroupData,
              ["name"],
              ["asc"]
            );
        } else if (newValue === "sort-by-adgroup-name-desc") {
          if (this.adgroupData && this.adgroupData.length)
            this.adgroupData = this.$sortArrayByProperty(
              this.adgroupData,
              ["name"],
              ["desc"]
            );
        }
      },
      immediate: true,
    },
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          this.adgroupData = this.googleDataModifier(newValue);
          this.filterData.campaigns = Array.from(
            new Set(this.adgroupData.map((x) => x.campaign_name))
          );

          this.selectedSortType = "sort-by-campaign-name-asc";
        }
      },
    },
    selectedAdsetPanel(newValue) {
      this.fetchAdsetDetail(newValue);
    },
    searchKey: _.debounce(function (newValue) {
      this.$set(this.filterData, "searchKey", newValue);
    }, 500),

    filterData: {
      handler() {
        this.selectedAdsetPanel = undefined;
      },
      deep: true,
    },
  },
  data() {
    return {
      clicked_adgroup_id: null,
      showImageSelectionDialog: false,
      googleImages: [],
      googleImagesLoading: false,
      imageCroppedImageIndex: null,
      imageCropperAdgrupId: null,
      showImageCropper: false,
      imageCropperData: null,
      selectedVariantIdx: null,
      filterData: {
        campaigns: [],
        searchKey: "",
      },
      searchKey: "",
      selectedSortType: "",
      sortSelections: [
        {
          text: "Sort by campaign name (Asc)",
          value: "sort-by-campaign-name-asc",
        },
        {
          text: "Sort by campaign name (Desc)",
          value: "sort-by-campaign-name-desc",
        },
        {
          text: "Sort by adgroup name (Asc)",
          value: "sort-by-adgroup-name-asc",
        },
        {
          text: "Sort by adgroup name (Desc)",
          value: "sort-by-adgroup-name-desc",
        },
      ],
      selectedAds: [],
      loading: false,
      fbAdsetData: [],
      adData: [],
      selectedAdsetPanel: null,
      adgroupData: [],
      facebookAssetData: [],
      loadingBtn: false,
      showPreview: false,
      preview_selected_ad_id: null,
      preview_selected_playable_id: null,
      showSummary: false,
      googleAssetData: [],
      generalMedias: [],
      videosLoading: false,
      imageTableHeaders: [
        { text: "Image", value: "image", sortable: false },
        { text: "Review Status", value: "review_status", sortable: false },
        { text: "Approval Status", value: "approval_status", sortable: false },
        { text: "Performance", value: "performance_label" },
      ],
    };
  },
  methods: {
    removeImage(imageIdx, adgroup_id) {
      const index = this.adgroupData.findIndex((x) => x.id === adgroup_id);
      if (index > -1) {
        this.adgroupData[index].assets.request_images.splice(imageIdx, 1);
      }
    },
    closeImageSelectionDialog() {
      this.showImageSelectionDialog = false;
      this.clicked_adgroup_id = null;
    },
    clickImageSelectionDialog(adgroup) {
      this.clicked_adgroup_id = adgroup.id;
      this.showImageSelectionDialog = true;
    },
    handleOnImageSelect(images) {
      if (this.clicked_adgroup_id !== null) {
        const index = this.adgroupData.findIndex(
          (x) => x.id === this.clicked_adgroup_id
        );
        if (index > -1) {
          this.adgroupData[index].assets.request_images = images;
        }
      }
    },
    handleOnPanelView(id) {
      if (this.selectedAdsetPanel === id) {
        this.selectedAdsetPanel = null;
        return;
      }
      this.selectedAdsetPanel = id;
    },
    isAdgroupModified(id) {
      const adgroup = this.adgroupData.find((x) => x.id === id);
      if (adgroup && adgroup.assets && adgroup.originalAssets) {
        return !_.isEqual(adgroup.assets, adgroup.originalAssets);
      }
      return false;
    },
    previewRequestData() {
      this.showSummary = true;
    },
    async replaceCreatives() {
      try {
        this.loadingBtn = true;
        this.showSummary = false;
        var requestData = [];
        for (let i = 0; i < this.adgroupData.length; i++) {
          var data = {
            ad_group_ad_id: null,
            campaign_id: null,
            campaign_name: null,
            adgroup_id: null,
            adgroup_name: null,
            descriptions: [],
            headlines: [],
            deleted_images: [],
            inserted_images: [],
            selected_creatives: [],
            deleted_videos: [],
            inserted_videos: [],
            deleted_playables: [],
            inserted_playables: [],
            video_limit_length: null,
          };
          const adgroupData = this.adgroupData[i];
          if (!adgroupData.assets) {
            continue;
          }
          data.campaign_id = adgroupData.campaign;
          data.campaign_name = adgroupData.campaign_name;
          data.adgroup_id = adgroupData.id;
          data.adgroup_name = adgroupData.name;
          data.selected_creatives = adgroupData.selectedCreatives.map((x) => ({
            title: x.ad_name,
            id: x.gaia_id,
          }));
          // 1- Description'ları değiştirilmiş olan assetleri bul.
          const descriptionsLength = adgroupData.assets.descriptions.length;
          [...Array(descriptionsLength).keys()].forEach((index) => {
            const originalDescription =
              adgroupData.originalAssets.descriptions[index];
            const description = adgroupData.assets.descriptions[index];
            if (!data.ad_group_ad_id) {
              data.ad_group_ad_id = originalDescription.ad_group_ad
                .split("~")
                .pop();
            }
            if (
              originalDescription.value.toLowerCase() !==
              description.value.toLowerCase()
            ) {
              data.descriptions.push({
                id: description.id,
                new_value: description.value,
                old_value: originalDescription.value,
              });
            }
          });
          // 2- Headline'ları değiştirilmiş olan assetleri bul.
          const headlinesLength = adgroupData.assets.headlines.length;
          [...Array(headlinesLength).keys()].forEach((index) => {
            const originalHeadline =
              adgroupData.originalAssets.headlines[index];
            const headline = adgroupData.assets.headlines[index];
            if (
              originalHeadline.value.toLowerCase() !==
              headline.value.toLowerCase()
            ) {
              data.headlines.push({
                id: headline.id,
                new_value: headline.value,
                old_value: originalHeadline.value,
              });
            }
          });
          data.deleted_images = adgroupData.assets.images
            .filter((x) => x.selected)
            .map((x) => ({
              id: x.id,
              asset: x.asset,
              value: x.value,
            }));
          data.inserted_images = adgroupData.assets.request_images.map(
            (x) => x.asset_name
          );
          data.deleted_videos = adgroupData.assets.videos
            .filter((x) => x.selected)
            .map((x) => ({
              id: x.id,
              asset: x.asset,
              ad_group_ad: x.ad_group_ad,
            }));
          data.video_limit_length =
            20 - adgroupData.assets.videos.filter((x) => !x.selected).length;
          data.deleted_playables = adgroupData.assets.media_bundles
            .filter((x) => x.selected)
            .map((x) => ({
              id: x.id,
              asset: x.asset,
              ad_group_ad: x.ad_group_ad,
            }));

          data.inserted_playables = adgroupData.assets.request_media_bundles;

          data.inserted_videos = adgroupData.assets.inserted_videos;
          requestData.push(data);
        }

        for (let i = 0; i < requestData.length; i++) {
          const data = requestData[i];
          // If there is no difference, just pass it.
          if (
            !data.descriptions.length &&
            !data.headlines.length &&
            !data.inserted_images.length &&
            !data.deleted_images.length &&
            !data.deleted_videos.length &&
            !data.inserted_videos.length &&
            !data.inserted_playables.length
          ) {
            continue;
          } else {
            let formData = new FormData();
            formData.append("descriptions", JSON.stringify(data.descriptions));
            formData.append("headlines", JSON.stringify(data.headlines));
            formData.append(
              "deleted_videos",
              JSON.stringify(data.deleted_videos)
            );
            formData.append(
              "deleted_playables",
              JSON.stringify(data.deleted_playables)
            );
            formData.append(
              "inserted_playables",
              JSON.stringify(data.inserted_playables)
            );
            formData.append(
              "selected_creatives",
              JSON.stringify(data.selected_creatives)
            );
            formData.append("campaign_id", data.campaign_id);
            formData.append("campaign_name", data.campaign_name);
            formData.append("adgroup_name", data.adgroup_name);
            formData.append(
              "deleted_images",
              JSON.stringify(data.deleted_images)
            );
            formData.append("adgroup_id", data.adgroup_id);
            formData.append("ad_group_ad_id", data.ad_group_ad_id);
            formData.append("video_limit_length", data.video_limit_length);
            formData.append(
              "inserted_videos",
              JSON.stringify(data.inserted_videos)
            );
            formData.append(
              "inserted_images",
              JSON.stringify(data.inserted_images)
            );
            try {
              const request = await this.$api.post(
                `burcha/google/tasks/create?game_id=${this.game_id}`,
                formData
              );
              if (request.status === 200) {
                this.$toast.success(`Task created for ${data.adgroup_name}.`, {
                  timeout: 5000,
                  position: "bottom-center",
                  icon: false,
                });
              }
            } catch (error) {
              this.errorHandler(error);
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    handleSelectCreative(adgroup_id, selectedCreativeIdx) {
      const index = this.adgroupData.findIndex((x) => x.id === adgroup_id);
      const value =
        this.adgroupData[index].selectedCreatives[selectedCreativeIdx].selected;
      this.$set(
        this.adgroupData[index].selectedCreatives[selectedCreativeIdx],
        "selected",
        !value
      );
    },
    computedSrc(image) {
      return URL.createObjectURL(image);
    },
    // eslint-disable-next-line
    handleVideoSelection(adgroup_id, videoIdx, maxSelectionCount) {
      // const currentSelectedCount = this.adgroupData[index].assets.videos.filter(
      //   (x) => !x.selected
      // ).length;
      // if (20 - currentSelectedCount >= maxSelectionCount) {
      //   if (this.adgroupData[index].assets.videos[videoIdx].selected) {
      //     this.adgroupData[index].assets.videos[videoIdx].selected = false;
      //   }
      //   return;
      // }
      const index = this.adgroupData.findIndex((x) => x.id === adgroup_id);
      this.adgroupData[index].assets.videos[videoIdx].selected =
        !this.adgroupData[index].assets.videos[videoIdx].selected;
    },
    async fetchAdsetDetail(id) {
      if (id) {
        const index = this.adgroupData.findIndex((x) => x.id === id);
        try {
          // Eğer zaten daha önce assetler çekilmiş ise yeniden çekmeye gerek yok.
          if (index < 0) return;
          if (this.adgroupData[index].assets) {
            return;
          }
          this.adgroupData[index].loading_assets = true;
          const request = await this.$api.get(
            `campaign-manager/google-assets?adgroup_id=${this.adgroupData[index].id}&game_id=${this.game_id}`
          );
          var data = request.data;

          data.descriptions = _.orderBy(
            data.descriptions,
            "performance_label",
            "asc"
          );
          data.headlines = _.orderBy(
            data.headlines,
            "performance_label",
            "asc"
          );
          data.images = _.orderBy(data.images, "performance_label", "asc");
          data.videos = _.orderBy(data.videos, "performance_label", "asc");
          data.media_bundles = _.orderBy(
            data.media_bundles,
            "performance_label",
            "asc"
          );
          data.images = data.images.map((x) => ({ ...x, selected: false }));
          data.request_images = [];
          data.request_images_variants = [];
          data.media_bundles = data.media_bundles.map((x) => ({
            ...x,
            selected: false,
          }));
          data.request_media_bundles = [];
          data.videos = data.videos.map((x) => ({
            ...x,
            selected: false,
          }));
          data.inserted_videos = [];

          this.adgroupData[index].assets = data;
          this.adgroupData[index].originalAssets = JSON.parse(
            JSON.stringify(data)
          );
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.adgroupData[index].loading_assets = false;
        }
      }
    },
    googleDataModifier(data) {
      if (!data.length) return [];
      var adgroups = [];
      data.forEach((campaign) => {
        campaign.ad_groups.forEach((adgroup) => {
          adgroups.push({
            ...adgroup,
            originalData: JSON.parse(JSON.stringify(adgroup)),
            campaign_id: campaign.campaign_id,
            campaign_name: campaign.campaign_name,
            loading_assets: false,
            assets: null,
            selectedCreatives: this.selectedCreatives.length
              ? this.selectedCreatives.map((x) => ({
                  ...x,
                  selected: true,
                }))
              : [],
          });
        });
      });
      return adgroups;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchPlayableAssets() {
      try {
        const request = await this.$api.get(
          `burcha/playables/google/all?game_id=${this.game_id}`
        );
        if (request) {
          this.googleAssetData = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async fetchGeneralMedias() {
      if (this.selectedCreatives.length) {
        const ids = this.selectedCreatives.map((x) => x.gaia_id).join(",");
        try {
          this.videosLoading = true;
          const request = await this.$api.get(
            `act/general-medias/get-by-creative-id?ids=${ids}`
          );
          this.generalMedias = request.data;
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.videosLoading = false;
        }
      }
    },
    async fetchGoogleImages() {
      try {
        this.googleImagesLoading = true;
        const request = await this.$api.get(
          `campaign-manager/google-images?game_id=${this.game_id}`
        );
        this.googleImages = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.googleImagesLoading = false;
      }
    },
  },
  computed: {
    isBtnDisabled() {
      if (this.adgroupData && this.adgroupData.length) {
        for (let i = 0; i < this.adgroupData.length; i++) {
          const adgroup = this.adgroupData[i];
          if (adgroup.assets) {
            if (adgroup.assets.descriptions) {
              // Descriptions'u aynı olup olmayanları kontrol et.
              for (let y = 0; y < adgroup.assets.descriptions.length; y++) {
                const description = adgroup.assets.descriptions[y];

                // Eğer boş bir description gönderiyorsa;
                if (!description.value) return true;

                const hasSameDescription = adgroup.assets.descriptions.find(
                  (x) =>
                    x.id !== description.id && description.value === x.value
                );

                if (hasSameDescription) return true;
              }

              // Headline aynı olup olmayanları kontrol et.
              for (let y = 0; y < adgroup.assets.headlines.length; y++) {
                const headline = adgroup.assets.headlines[y];
                // Eğer boş bir headline gönderiyorsa;
                if (!headline.value) return true;

                const hasSameHeadline = adgroup.assets.headlines.find(
                  (x) => x.id !== headline.id && headline.value === x.value
                );

                if (hasSameHeadline) return true;
              }
            }
          }
        }

        return false;
      }
      return true;
    },
    computedDifferenceData() {
      if (!this.adgroupData) return [];
      if (!this.adgroupData.length) return [];

      if (!this.adgroupData.filter((x) => x.assets).length) return [];

      var data = [];
      for (let i = 0; i < this.selectedCampaigns.length; i++) {
        const campaign = this.selectedCampaigns[i];
        data.push({
          campaign: campaign.campaign_name,
          id: campaign.campaign_id,
          batches: [],
        });
      }

      for (let i = 0; i < this.adgroupData.length; i++) {
        const adgroup = this.adgroupData[i];
        if (adgroup.assets) {
          var temp = {
            batch_name: adgroup.name,
            differences: [],
          };

          const campaignIdx = data.findIndex(
            (x) => x.id === adgroup.campaign_id
          );
          if (campaignIdx === -1) {
            continue;
          }

          // 1. check descriptions
          const descriptions = adgroup.assets.descriptions;
          descriptions.forEach((description) => {
            const value = description.value;
            const originalValue = adgroup.originalAssets.descriptions.find(
              (x) => x.id == description.id
            );

            if (originalValue && originalValue.value !== value) {
              temp.differences.push({
                type: "description",
                value: description.value,
                originalValue: originalValue.value,
                campaign_name: adgroup.campaign_name,
              });
            }
          });

          // 2. check descriptions
          const headlines = adgroup.assets.headlines;
          headlines.forEach((headline) => {
            const value = headline.value;
            const originalValue = adgroup.originalAssets.headlines.find(
              (x) => x.id == headline.id
            );

            if (originalValue && originalValue.value !== value) {
              temp.differences.push({
                type: "headline",
                value: headline.value,
                originalValue: originalValue.value,
                campaign_name: adgroup.campaign_name,
              });
            }
          });

          // 3. check images
          const selectedImagesLength = adgroup.assets.images.filter(
            (x) => x.selected
          ).length;
          const addedImagesLength = adgroup.assets.request_images.length;

          temp.differences.push({
            type: "images",
            add: addedImagesLength,
            delete: selectedImagesLength,
            campaign_name: adgroup.campaign_name,
          });

          // 4. check html5
          const selectedMediaBundles = adgroup.assets.media_bundles.filter(
            (x) => x.selected
          ).length;
          const addedMediaBundles = adgroup.assets.request_media_bundles.length;

          temp.differences.push({
            type: "playables",
            add: addedMediaBundles,
            delete: selectedMediaBundles,
            campaign_name: adgroup.campaign_name,
          });

          // 4. check videos
          const selectedVideos = adgroup.assets.videos.filter(
            (x) => x.selected
          ).length;
          const addedVideos = adgroup.assets.inserted_videos.length;

          temp.differences.push({
            type: "videos",
            add: addedVideos,
            delete: selectedVideos,
            campaign_name: adgroup.campaign_name,
          });

          data[campaignIdx].batches.push(temp);
        }
      }
      return data;
    },
  },
  async mounted() {
    await this.fetchPlayableAssets();
    await this.fetchGeneralMedias();
    await this.fetchGoogleImages();
  },
  components: { SummaryDialog, ImageSelectionDialog },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.panel-header {
  color: rgba($color: #000000, $alpha: 0.4);
}

.panel-header.edited {
  .panel-campaign-name {
    color: #0c4a6e !important;
  }
  .panel-adgroup-name {
    color: #881337 !important;
  }
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}
.adgroup_image {
  width: 75px;
  height: 75px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.striped-bg {
  background: repeating-linear-gradient(
    45deg,
    #f3f3f3,
    #f3f3f3 5px,
    #c5c5c5 5px,
    #c5c5c5 10px
  );
}
</style>
