<template>
  <div class="bg-white p-6 w-full">
    <div class="flex items-center justify-between pb-6">
      <h1 class="font-semibold text-[18px]">Networks</h1>

      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="showCreateNetworkDialog = true"
      >
        New Network
      </v-btn>
    </div>

    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items-per-page="100"
        :items="data"
      >
        <template v-slot:[`item.bid_change_time`]="{ item }">
          <span class="">
            {{ $formatDate(item.bid_change_time, "YYYY-MM-DD") }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex items-center gap-2">
            <span
              class="font-semibold text-[10px] text-blue-400 hover:underline cursor-pointer hover:text-blue-600"
              @click="editNetwork(item)"
              >Edit</span
            >
          </div>
        </template>
      </v-data-table>
    </div>

    <CreateNetworkDialog
      :show="showCreateNetworkDialog"
      @closed="showCreateNetworkDialog = false"
      @create="handleOnCreateNetwork"
    />

    <UpdateNetworkDialog
      :show="showUpdateNetworkDialog"
      @closed="showUpdateNetworkDialog = false"
      :clickedData="clickedData"
      @update="handleOnUpdateNetwork"
    />
  </div>
</template>

<script>
import CreateNetworkDialog from "@/components/tools/utility/CreateNetworkDialog.vue";
import UpdateNetworkDialog from "@/components/tools/utility/UpdateNetworkDialog.vue";

export default {
  data() {
    return {
      headers: [
        { text: "Network Origin", value: "network_name_origin" },
        { text: "Network", value: "network_name" },
        { text: "Type", value: "network_type" },
        { text: "SK Partner Name", value: "sk_partner_name" },
        { text: "Billing Type", value: "billing_type" },
        { text: "Is CPA", value: "is_cpa" },
        { text: "Cpa Conversion Window", value: "cpa_conversion_window" },
        { text: "Dimension", value: "dimension" },
        { text: "Bid Change Time", value: "bid_change_time" },
        { text: "Dc Status", value: "dc_status" },
        { text: "Fb Status", value: "fb_status" },
        { text: "Cost Reporting", value: "cost_reporting" },
        { text: "Event Completion Rate", value: "event_completion_rate" },
        { text: "Cost Date", value: "cost_date" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,
      showCreateNetworkDialog: false,
      showDeleteNetworkDialog: false,
      showUpdateNetworkDialog: false,
      data: [],
      clickedData: null,
    };
  },
  components: {
    CreateNetworkDialog,
    UpdateNetworkDialog,
  },
  methods: {
    handleOnUpdateNetwork(data) {
      const index = this.data.findIndex((x) => x.row_id === data.row_id);
      if (index > -1) {
        this.$set(this.data, index, JSON.parse(JSON.stringify(data)));
      }
    },
    handleOnDeleteNetwork() {
      const index = this.data.findIndex(
        (x) => x.row_id === this.clickedData.row_id
      );
      if (index > -1) {
        this.data.splice(index, 1);
      }
    },

    editNetwork(row) {
      this.clickedData = row;
      this.showUpdateNetworkDialog = true;
    },

    deleteNetwork(row) {
      this.clickedData = row;
      this.showDeleteNetworkDialog = true;
    },

    handleOnCreateNetwork($event) {
      this.data.push({
        ...$event,
        is_cpa: $event.is_cpa === 1 ? "True" : "False",
      });
    },

    async fetchAllNetworks() {
      try {
        this.loading = true;
        const response = await this.$api.get("utils/reference-networks/all");
        this.data = response.data.map((x) => ({
          ...x,
          is_cpa: x.is_cpa === 1 ? "True" : "False",
        }));
      } catch (error) {
        this.$globalErrorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchAllNetworks();
  },
};
</script>

<style scoped lang="scss"></style>
